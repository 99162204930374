import request from '@/utils/request'

// 临时code登录
export const tempCodeLogin = (code: string) => {
	return request({
		url: '/prod-api/client-auth/tempCode/login',
		method: 'post',
		data: {
			code: code
		}
	})
}