export default (local_url: string, par: string) => {
    let get = local_url.indexOf(par + "=");
    if (get == -1) {
        return '';
    }
    let get_par = local_url.slice(par.length + get + 1);
    let nextPar = get_par.indexOf("&");
    if (nextPar != -1) {
        get_par = get_par.slice(0, nextPar);
    }
    get_par = decodeURIComponent(get_par)
    return get_par;
}