import { createStore } from 'vuex'

export default createStore({
	state: {
		// 是否显示fixed按钮 根据键盘是否弹出来判断
		fixedBtn: true,
		token: "",
	},
	getters: {
	},
	mutations: {
		hideFixed(state) {
			state.fixedBtn = false;
		},
		showFixed(state) {
			state.fixedBtn = true;
		},
		Token(state, token) {
			state.token = token;
		}
	},
	actions: {
	},
	modules: {
	}
})
