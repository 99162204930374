import axios from 'axios';
import { showDialog } from 'vant';
import store from '@/store';
import 'vant/es/dialog/style';

const instance = axios.create({
    timeout: 20000,
    baseURL: process.env.NODE_ENV === "development" ? "" : "",
    withCredentials: true // 允许携带cookie
});

instance.interceptors.request.use((config: any) => {
    if (config.method === 'post' || config.method === 'put') {
        if (!config.headers["Content-Type"]) {
            config.headers["Content-Type"] = "application/json;";
        }
        config.data = JSON.stringify(config.data);
    }
    config.headers["Authorization"] = store.state.token
    // config.url = `https://art.test.zcunsoft.com` + config.url
    config.url = `https://art.yitulu.com` + config.url
    
    return config;
}, (err: any) => {
    return Promise.reject(err);
});

instance.interceptors.response.use((res: any) => {
    // 0 成功
    if (res.data.code != 200) {
        if (!(res.config as any).hideError) {
            showDialog({
                message: res.data.msg,
            }).then(() => {
                // on close
            });
        }
        return Promise.reject(res);
    }
    return res;
}, (error: any) => {
    if (error.message == 'timeout of 5000ms exceeded') {
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export default instance;